<template>
  <div>
    <content-box title="Practice Schedule" class="col-lg-9 col-xl-7">
      <div
        v-for="(program, index) in programs"
        :key="program.program.split().join('') + '-' + index"
      >
        <div class="mb-1 font-weight-bold maroon-text">
          <u>{{ program.program }}</u>
        </div>

        <div class="container">
          <div class="row justify-content-sm-center">
            <div
              class="
                col-4 col-sm-3 col-md-2
                p-0
                pl-2 pl-sm-0
                font-weight-bold
                maroon-text
              "
            >
              Location:
            </div>
            <div class="col-8 col-sm-6 col-md-4 p-0">
              {{ program.location }}
            </div>
          </div>
          <div class="row justify-content-sm-center">
            <div class="col-4 col-sm-3 col-md-2 p-0 pl-2 pl-sm-0"></div>
            <div class="col-8 col-sm-6 col-md-4 p-0">
              {{ program.street }}
            </div>
          </div>
          <div class="row justify-content-sm-center">
            <div class="col-4 col-sm-3 col-md-2 p-0 pl-2 pl-sm-0"></div>
            <div class="col-8 col-sm-6 col-md-4 p-0">
              {{ program.city }}
            </div>
          </div>

          <template v-if="program.preseason">
            <div class="row justify-content-sm-center mt-3">
              <div
                class="
                  col-4 col-sm-3 col-md-2
                  p-0
                  pl-2 pl-sm-0
                  font-weight-bold
                  maroon-text
                "
              >
                Pre-Season
              </div>
              <div class="col-8 col-sm-6 col-md-4 p-0"></div>
            </div>
            <div class="row justify-content-sm-center mt-2">
              <div
                class="
                  col-4 col-sm-3 col-md-2
                  p-0
                  pl-2 pl-sm-0
                  font-weight-bold
                  maroon-text
                "
              >
                <div class="pl-3">Dates:</div>
              </div>
              <div class="col-8 col-sm-6 col-md-4 p-0">
                {{ program.preseason.dates }}
              </div>
            </div>
            <div class="row justify-content-sm-center">
              <div
                class="
                  col-4 col-sm-3 col-md-2
                  p-0
                  pl-2 pl-sm-0
                  font-weight-bold
                  maroon-text
                "
              >
                <div class="pl-3">Days:</div>
              </div>
              <div class="col-8 col-sm-6 col-md-4 p-0">
                {{ program.preseason.days }}
              </div>
            </div>
            <div class="row justify-content-sm-center">
              <div
                class="
                  col-4 col-sm-3 col-md-2
                  p-0
                  pl-2 pl-sm-0
                  font-weight-bold
                  maroon-text
                "
              >
                <div class="pl-3">Time:</div>
              </div>
              <div class="col-8 col-sm-6 col-md-4 p-0">
                {{ program.preseason.time }}
              </div>
            </div>
          </template>

          <template v-if="program.season">
            <div class="row justify-content-sm-center mt-3">
              <div
                class="
                  col-4 col-sm-3 col-md-2
                  p-0
                  pl-2 pl-sm-0
                  font-weight-bold
                  maroon-text
                "
              >
                Regular Season
              </div>
              <div class="col-8 col-sm-6 col-md-4 p-0 font-weight-bold">
                <template v-if="program.season === undefined">
                  Practice Dates and Times are TBD
                </template>
              </div>
            </div>
            <div class="row justify-content-sm-center mt-2">
              <div
                class="
                  col-4 col-sm-3 col-md-2
                  p-0
                  pl-2 pl-sm-0
                  font-weight-bold
                  maroon-text
                "
              >
                <div class="pl-3">Dates:</div>
              </div>
              <div class="col-8 col-sm-6 col-md-4 p-0">
                {{ program.season.dates }}
              </div>
            </div>
            <div class="row justify-content-sm-center">
              <div
                class="
                  col-4 col-sm-3 col-md-2
                  p-0
                  pl-2 pl-sm-0
                  font-weight-bold
                  maroon-text
                "
              >
                <div class="pl-3">Days:</div>
              </div>
              <div class="col-8 col-sm-6 col-md-4 p-0">
                {{ program.season.days }}
              </div>
            </div>
            <div class="row justify-content-sm-center">
              <div
                class="
                  col-4 col-sm-3 col-md-2
                  p-0
                  pl-2 pl-sm-0
                  font-weight-bold
                  maroon-text
                "
              >
                <div class="pl-3">Time:</div>
              </div>
              <div class="col-8 col-sm-6 col-md-4 p-0">
                {{ program.season.time }}
              </div>
            </div>
            <div class="row justify-content-start mt-3">
              <registration-button class="col-sm-2 offset-sm-3 btn-sm mt-2" />
            </div>
          </template>
          <template v-else>
            <div class="row justify-content-sm-center mt-3">
              <div
                class="
                  col-4 col-sm-3 col-md-2
                  p-0
                  pl-2 pl-sm-0
                  font-weight-bold
                  maroon-text
                "
              ></div>
              <div
                class="col-8 col-sm-6 col-md-4 p-0 font-weight-bold maroon-text"
              >
                Practice Dates and Times are TBD
              </div>
            </div>
          </template>
        </div>
        <hr v-if="!processingLastItem(programs, index)" />
      </div>
    </content-box>

    <!-- ****** TODO: ADD IN THE TOURNAMENT SCHEDULE, WHEN ONE IS CREATED ****** -->
    <!--    <content-box-->
    <!--      title="Tournament Schedule (Tenative)"-->
    <!--      class="col-lg-6 col-xl-4"-->
    <!--    >-->
    <!--      <small class="d-block w-100 mb-1 text-right"-->
    <!--        >Updated {{ lastUpdated }}</small-->
    <!--      >-->

    <!--      <div v-for="event in scheduledEvents" :key="event.id">-->
    <!--        <div v-if="event.showMonth" class="mb-1 font-weight-bold maroon-text">-->
    <!--          {{ event.month }}-->
    <!--        </div>-->

    <!--        &lt;!&ndash; event details &ndash;&gt;-->
    <!--        <div class="container">-->
    <!--          <div-->
    <!--            class="row mb-2 justify-content-end justify-content-sm-center"-->
    <!--            :class="{ 'font-italic': !event.confirmed }"-->
    <!--          >-->
    <!--            <div class="col-2 col-sm-1 p-0 pl-2 font-weight-bold maroon-text">-->
    <!--              {{ event.day }}-->
    <!--            </div>-->

    <!--            <div class="col-10 col-sm-8 col-md-6 pr-0">-->
    <!--              <div class="font-weight-bold maroon-text">-->
    <!--                {{ event.name }}-->
    <!--              </div>-->

    <!--              <div class="">-->
    <!--                {{ event.facility }}-->
    <!--              </div>-->

    <!--              <div class="">-->
    <!--                {{ event.location }}-->
    <!--              </div>-->

    <!--              <div class="">-->
    <!--                {{ event.participants }}-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->

    <!--      <hr />-->

    <!--      <small class="d-block col-12 mt-3">-->
    <!--        <div class="float-left text-right">*</div>-->
    <!--        <p class="float-left col-11 text-left">-->
    <!--          Any event that appears in-->
    <!--          <span class="font-italic">italics</span> is tentatively scheduled. We-->
    <!--          are waiting for confirmation the event will take place.-->
    <!--        </p>-->
    <!--      </small>-->
    <!--    </content-box>-->
  </div>
</template>

<script>
import { parse, format } from 'date-fns'

import seasonFunctions from '@/commons/seasonFunctions'

import apiService from '@/services/apiService'

import registrationButton from '@/components/registration/registrationButton'
import contentBox from '@/components/contentBox'

export default {
  name: 'schedules',
  components: {
    registrationButton,
    contentBox,
  },
  data() {
    return {
      programs: [
        {
          program: 'K - 3rd Grade Program',
          location: 'Pentucket Lake Elementary School',
          street: '252 Concord St.',
          city: 'Haverhill, MA 01830',
          preseason: {
            dates: `October 2, ${seasonFunctions.getSeason()}`,
            days: 'Monday & Wednesday',
            time: '6-7:30p',
          },
          season: {
            dates: `November 4, ${seasonFunctions.getSeason()} - January 31, ${
              seasonFunctions.getSeason() + 1
            }`,
            days: 'Monday, Tuesday & Thursday',
            time: '5:30-6:30p',
          },
        },
        {
          program: '4th - 8th Grade Program',
          location: 'Pentucket Lake Elementary School',
          street: '252 Concord St.',
          city: 'Haverhill, MA 01830',
          preseason: {
            dates: `October 2, ${seasonFunctions.getSeason()}`,
            days: 'Monday & Wednesday',
            time: '6-7:30p',
          },
          season: {
            dates: `November 4, ${seasonFunctions.getSeason()} - January 31, ${
              seasonFunctions.getSeason() + 1
            }`,
            days: 'Monday, Tuesday & Thursday',
            time: '6:30-8:00p',
          },
        },
      ],

      lastUpdated: null,
      schedule: [],

      eventProcessing: {
        month: '',
      },
    }
  },
  created() {
    this.fetchSchedule()
  },
  computed: {
    scheduledEvents() {
      let vm = this
      let currMonth = ''

      return this.schedule.map(function (event) {
        let d = parse(event.date, 'yyyy-MM-dd', new Date())
        let month = format(d, 'MMMM')
        let day = format(d, 'd')

        let showMonth = currMonth !== month
        if (showMonth) {
          currMonth = month
        }

        let name = event.name
        if (!name) {
          name = event.eventType
        }

        let location = event.city
        if (event.state) {
          location += ', ' + event.state
        }

        let ageGroup = vm.toOrdinal(event['participants'].youngest)
        let maxAgeGroup = vm.toOrdinal(event['participants'].oldest)

        if (maxAgeGroup && maxAgeGroup !== ageGroup) {
          ageGroup += '-' + maxAgeGroup
        }

        if (event['participants'].dualTeam) {
          ageGroup += ', Dual Team'
        }

        return {
          id: event.id,
          showMonth: showMonth,
          month: month,
          day: day,
          name: name,
          facility: event['facility'],
          location: location,
          participants: ageGroup,
          confirmed: event['confirmed'],
        }
      })
    },
  },
  methods: {
    fetchSchedule() {
      apiService.getSchedule().then((yay) => {
        this.lastUpdated = format(
          parse(yay.lastUpdated, 'yyyy-MM-dd', new Date()),
          'MM/dd/yyyy'
        )

        this.schedule = yay.events
      })
    },

    processingLastItem(items, index) {
      return index === items.length - 1
    },

    toOrdinal(value) {
      let v = Number(value)

      if (isNaN(v)) {
        return value
      }

      switch (v) {
        case 1:
          return value + 'st'
        case 2:
          return value + 'nd'
        case 3:
          return value + 'rd'
        default:
          return value + 'th'
      }
    },
  },
}
</script>
